import {StyleSheet, Image, View, ScrollView} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import LinearGradient from '@/components/basic/linear-gradient';
import Drawer from '@/components/basic/drawer';
import {DrawerRef} from '@/components/basic/drawer/drawer';
import {BasicObject} from '@/types';
import Check from '../svg/check';
import {useTranslation} from 'react-i18next';
const down = require('@components/assets/icons/down.webp');
const close = require('@components/assets/icons/close.webp');
const LiveFilter = ({
  sortBy = [],
  categories = [],
  currentCategory = '',
  currentSort = '',
  onChange = () => {},
}: {
  sortBy: BasicObject[];
  currentCategory: string;
  currentSort: string;
  categories: BasicObject[];
  onChange: (type: string, value: string) => void;
}) => {
  const {i18n} = useTranslation();
  const drawRef = React.useRef<DrawerRef>(null);
  const [currentFilter, setCurrentFilter] = React.useState('Category');

  const List = React.useMemo(() => {
    return currentFilter === 'Category' ? categories : sortBy;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter]);

  const sortLabel = React.useMemo(() => {
    return sortBy.find(item => item.value === currentSort)?.label;
  }, [sortBy, currentSort]);

  const categoryLabel = React.useMemo(() => {
    return categories.find(item => item.value === currentCategory)?.label;
  }, [categories, currentCategory]);

  return (
    <>
      <LinearGradient
        colors={[theme.basicColor.white, theme.backgroundColor.lightGrey]}
        style={[
          theme.padding.lrl,
          theme.flex.row,
          styles.gap,
          theme.padding.tbl,
        ]}>
        <NativeTouchableOpacity
          onPress={() => {
            setCurrentFilter('Category');
            drawRef.current?.open();
          }}
          style={[styles.itemContainer, theme.margin.rightl]}>
          <Text numberOfLines={1} color={theme.basicColor.dark}>
            {i18n.t('live.label.category')}: {categoryLabel}
          </Text>
          <Image style={[styles.icon]} source={down} />
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          onPress={() => {
            setCurrentFilter('Sort');
            drawRef.current?.open();
          }}
          style={[styles.itemContainer]}>
          <Text numberOfLines={1} color={theme.basicColor.dark}>
            {i18n.t('live.label.sort')}: {sortLabel}
          </Text>
          <Image style={[styles.icon]} source={down} />
        </NativeTouchableOpacity>
      </LinearGradient>
      <Drawer mode="bottom" ref={drawRef}>
        <View
          style={[
            styles.drawContainer,
            // eslint-disable-next-line react-native/no-inline-styles
            currentFilter === 'Category' && {maxHeight: 300},
          ]}>
          <View
            style={[
              styles.drawHeader,
              theme.flex.row,
              theme.flex.between,
              theme.flex.centerByCol,
            ]}>
            <Text
              size="medium"
              fontFamily="fontInterBold"
              color={theme.basicColor.dark}>
              {i18n.t(`live.label.${currentFilter.toLowerCase()}`)}
            </Text>
            <NativeTouchableOpacity onPress={() => drawRef.current?.close()}>
              <Image source={close} style={styles.close} />
            </NativeTouchableOpacity>
          </View>
          <ScrollView contentContainerStyle={styles.gap}>
            {List.map((item, index) => {
              const check =
                item.value ===
                (currentFilter === 'Category' ? currentCategory : currentSort);
              return (
                <NativeTouchableOpacity
                  onPress={() => {
                    drawRef.current?.close();
                    onChange(currentFilter, item.value);
                  }}
                  style={[styles.item, check && styles.itemCheck]}
                  key={index}>
                  <Text size="medium" color={theme.fontColor.second}>
                    {item.label}
                  </Text>
                  {check && <Check />}
                </NativeTouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      </Drawer>
    </>
  );
};

const styles = StyleSheet.create({
  gap: {
    paddingHorizontal: 20,
  },
  itemContainer: {
    padding: 8,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    flex: 1,
    borderColor: theme.backgroundColor.grey,
    borderRadius: 4,
  },
  icon: {
    width: 14,
    height: 14,
  },
  close: {
    width: 24,
    height: 24,
  },
  drawContainer: {
    // paddingBottom: 12,
  },
  drawHeader: {
    paddingHorizontal: 20,
    paddingVertical: 24,
  },
  item: {
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    justifyContent: 'space-between',
  },
  itemCheck: {
    backgroundColor: '#F6F7FB',
  },
});

export default LiveFilter;
