import React from 'react';
import theme from '@style';
import Text from '@basicComponents/text';
import {View} from 'react-native';
import {NavigatorScreenProps} from '@types';
import NavTitle from '@basicComponents/nav-title';
import {goBack, goTo} from '@utils';
import CodeInput from './components/code-input';
import {styles} from './login.style';
import globalStore from '@services/global.state';
import Button from '@basicComponents/button';
import {updatePassword} from './login.service';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {useTranslation} from 'react-i18next';
import {homePage} from '@/config';

const SetPassword = (props: NavigatorScreenProps) => {
  const {i18n} = useTranslation();
  const params = props.route.params as {
    fromLogin?: boolean;
    fromForgot?: number;
  };
  const fromLogin = React.useRef(params?.fromLogin || false).current;
  const [pwd, setPWD] = React.useState('');
  const [confrimPWD, setComfirmPWD] = React.useState('');
  const [confirmed, setConfirmed] = React.useState(false);
  const goNextPage = () => {
    // 只要修改密码就必须重新登录，登录后回到首页
    goTo('Login', {
      backPage: homePage,
      sucessPage: homePage,
    });
  };
  const fromForgot = React.useMemo(() => {
    return !!params?.fromForgot;
  }, [params?.fromForgot]);
  return (
    <View style={[theme.fill.fill, theme.flex.col, theme.background.white]}>
      <NavTitle
        onBack={fromLogin ? undefined : () => goBack()}
        title={
          fromForgot
            ? i18n.t('login.label.setAPassword')
            : i18n.t('login.label.set-password')
        }
        rightNode={
          fromLogin ? (
            <NativeTouchableOpacity onPress={goNextPage}>
              <Text size="medium" color={theme.basicColor.primary}>
                {i18n.t('login.label.skip')}
              </Text>
            </NativeTouchableOpacity>
          ) : null
        }
      />
      <View style={[{padding: theme.paddingSize.l * 2}, theme.flex.col]}>
        <View
          style={[
            styles.interval,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              height: 66,
            },
          ]}>
          <CodeInput
            switchIndex={0}
            userPassword={pwd}
            setValueOrCode={setPWD}
          />
        </View>
        <View
          style={[
            styles.interval,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              height: 66,
            },
          ]}>
          <CodeInput
            switchIndex={0}
            userPassword={confrimPWD}
            setValueOrCode={setComfirmPWD}
          />
        </View>
        <Button
          onPress={() => {
            if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,15}$/.test(pwd)) {
              globalStore.globalTotal.next({
                type: 'warning',
                message: i18n.t('login.tip.passwordRule'),
              });
              return;
            }
            if (pwd !== confrimPWD) {
              globalStore.globalTotal.next({
                type: 'warning',
                message: i18n.t('login.tip.pwd-dif'),
              });
              return;
            }
            globalStore.globalLoading.next(true);
            updatePassword(pwd)
              .then(() => {
                setConfirmed(true);
                globalStore.globalTotal.next({
                  type: 'success',
                  message: i18n.t('login.tip.modified'),
                });
                globalStore.token = null;
                globalStore.userInfo = null;
                setTimeout(() => {
                  goNextPage();
                }, 3000);
              })
              .finally(() => {
                globalStore.globalLoading.next(false);
              });
          }}
          disabled={!pwd || !confrimPWD || confirmed}>
          <Text color={theme.basicColor.white} size="large" fontWeight="700">
            {i18n.t('label.confirm')}
          </Text>
        </Button>
      </View>
    </View>
  );
};

export default SetPassword;
