import theme from '@/style';
import React from 'react';
import {KeyboardAvoidingView, ScrollView, View} from 'react-native';
import {goBack, goTo} from '@/utils';
import NavTitle from '@/components/basic/nav-title';
import {useTranslation} from 'react-i18next';
import PhoneInput from './components/phone-input';
import CodeInput from './components/code-input';
import {styles} from './login.style';
import Button from '@basicComponents/button';
import globalStore from '@/services/global.state';
import {userLogin} from './login.service';
import {apiSendPhoneCode, defaultPhoneCode} from '@/config';
import {clearAllWin} from '@/components/utils/gameWin';
import {BasicObject, NavigatorScreenProps} from '@/types';

const ForgotPassword = (props: NavigatorScreenProps) => {
  const {i18n} = useTranslation();
  const [userPhone, setUserPhone] = React.useState('');
  const [userPhoneCode, setUserPhoneCode] = React.useState(defaultPhoneCode);
  const [OTPCode, setOTPCode] = React.useState('');
  /** 登录成功的目标页面,回到原页面操作 */
  const sucessPage =
    ((props.route.params as BasicObject)?.sucessPage as string) || null;
  /** 登录成功的目标页面,回到原页面操作 */
  const sucessPageParams =
    ((props.route.params as BasicObject)?.sucessPageParams as BasicObject) ||
    null;
  const setValueOrCode = (value: string) => {
    setOTPCode(value);
  };
  return (
    <KeyboardAvoidingView
      behavior="height"
      style={[
        theme.fill.fill,
        theme.flex.col,
        theme.background.white,
        theme.position.rel,
      ]}>
      <ScrollView keyboardShouldPersistTaps="always" style={[theme.flex.flex1]}>
        <NavTitle
          onClose={() => {
            goBack();
          }}
          title={i18n.t('login.label.forgotPassword')}
        />
        <View
          style={[
            {
              padding: theme.paddingSize.l * 2,
            },
          ]}>
          <PhoneInput
            userPhone={userPhone}
            setUserPhone={setUserPhone}
            userPhoneCode={userPhoneCode}
            setUserPhoneCode={setUserPhoneCode}
          />
          <View style={styles.interval} />
          <CodeInput
            setValueOrCode={setValueOrCode}
            switchIndex={1}
            userPhone={userPhone}
            userPhoneCode={userPhoneCode}
            OTPCode={OTPCode}
            userPassword={''}
          />
          <View
            style={{
              marginVertical: theme.paddingSize.m * 2,
            }}>
            <Button
              buttonStyle={[styles.loginButton, theme.overflow.hidden]}
              onPress={() => {
                if (userPhone === '') {
                  globalStore.globalWaringTotal(i18n.t('login.tip.no-phone'));
                  return;
                }
                if (OTPCode === '') {
                  globalStore.globalWaringTotal(i18n.t('login.tip.no-otp'));
                  return;
                }
                globalStore.globalLoading.next(true);
                userLogin(
                  (apiSendPhoneCode ? userPhoneCode : '') + userPhone,
                  OTPCode,
                )
                  .then(res => {
                    if (typeof res === 'string') {
                      globalStore.token = res;
                    } else {
                      globalStore.token = res.token;
                    }
                    clearAllWin().then();
                    goTo('SetPassword', {
                      fromForgot: 1,
                      sucessPage,
                      sucessPageParams,
                    });
                  })
                  .catch(() => {
                    globalStore.globalLoading.next(false);
                  })
                  .finally(() => globalStore.globalLoading.next(false));
              }}
              disabled={OTPCode.length !== 6}
              type="linear-primary"
              color={theme.basicColor.white}
              titleBold
              title={i18n.t('login.label.next')}
            />
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default ForgotPassword;
