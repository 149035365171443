import {View, Image, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import React from 'react';
import theme from '@/style';
import {BasicObject, SafeAny} from '@/types';
import LazyImage from '../../components/basic/image/lazy-image';
import {useResponsiveDimensions} from '@/utils';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
const user = require('@components/assets/icons/user.webp');
const LiveListItem = ({
  gameInfo = {},
  index = 0,
  scrollY = 0,
  headerHeight = 0,
  onPress = () => {},
  isOld = true,
}: {
  gameInfo: BasicObject;
  onPress: () => void;
  pageLoading?: boolean;
  index: number;
  scrollY?: number;
  headerHeight?: number;
  isOld?: boolean;
}) => {
  const {calcActualSize, designWidth} = useScreenSize();
  const {width, height} = useResponsiveDimensions();
  const [selfHeight, setSelfHeight] = React.useState(0);
  const isPlaying = React.useRef(false);
  const itemWidth = React.useMemo(() => {
    const padding = 12 * 2;
    const gap = 8;
    const oldWidth = (width - padding - gap) / 2;
    const newWidth = calcActualSize((designWidth - padding - 2 * gap) / 3);
    return isOld ? oldWidth : newWidth;
  }, [width, isOld, calcActualSize, designWidth]);
  const itemHeight = itemWidth * (isOld ? 342 / 516 : 155.68 / 112);

  const [paused, setPaused] = React.useState(true);
  const videoRef = React.useRef<SafeAny>(null);

  const toTop = React.useMemo(() => {
    const total = index * (selfHeight + 8) + headerHeight;
    return total;
  }, [selfHeight, index, headerHeight]);

  React.useEffect(() => {
    const measureHeight = height / 3;
    const leftHeight = toTop - measureHeight;
    if (leftHeight > 0) {
      if (scrollY >= leftHeight && scrollY <= leftHeight + itemHeight) {
        if (isPlaying.current) {
          return;
        }
        setPaused(false);
        videoRef.current?.play();
        isPlaying.current = true;
      } else {
        if (!isPlaying.current) {
          return;
        }
        setPaused(true);
        videoRef.current?.pause();
        isPlaying.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toTop, scrollY, height]);

  return (
    <NativeTouchableOpacity
      onLayout={e => setSelfHeight(e.nativeEvent.layout.height)}
      onPress={onPress}
      style={[
        styles.itemContainer,
        {
          width: itemWidth,
        },
      ]}>
      {gameInfo.otherUrl && gameInfo.otherUrl.includes('mp4') ? (
        <View
          style={[
            {
              width: itemWidth,
              height: itemHeight,
            },
          ]}>
          <video
            ref={videoRef}
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              flex: 1,
              objectFit: 'cover',
            }}
            src={gameInfo.otherUrl}
            muted
            loop
            playsInline>
            Your browser does not support the video tag.
          </video>
          {paused && (
            <View style={styles.cover}>
              <LazyImage
                imageUrl={gameInfo.otherUrl || gameInfo.gamePic}
                width={itemWidth}
                height={itemHeight}
              />
            </View>
          )}
        </View>
      ) : (
        <LazyImage
          imageUrl={gameInfo.otherUrl || gameInfo.gamePic}
          width={itemWidth}
          height={itemHeight}
          resizeMode="cover"
        />
      )}

      {isOld && (
        <View style={[theme.flex.row, theme.padding.lrs, styles.infoContainer]}>
          <View style={[theme.flex.flex1, theme.margin.rightl]}>
            <Text
              numberOfLines={1}
              fontFamily="fontInterBold"
              color={theme.basicColor.dark}>
              {gameInfo.name}
            </Text>
            <Text color={theme.fontColor.secAccent}>{gameInfo.provider}</Text>
          </View>
          <View style={[theme.flex.centerByCol]}>
            <Image source={user} style={styles.icon} />
            <Text color={theme.fontColor.second}>{gameInfo.playersNumber}</Text>
          </View>
        </View>
      )}
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    marginBottom: 8,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: theme.basicColor.white,
  },
  icon: {
    width: 12,
    height: 12,
  },
  cover: {
    position: 'absolute',
  },
  infoContainer: {
    height: 40,
    alignItems: 'center',
  },
});

export default LiveListItem;
