import {BasicObject} from '@/types';
import {http, indusWinHttp} from '@/utils';

export interface LiveBannerItem {
  tripartiteUniqueness: string;
  bannerImg: string;
  gameLink: string;
  otherUrl: string;
  provider: string;
  source: string;
  gameId: number;
  otherParams?: string;
}

export interface LiveHotGameItem {
  tripartiteUniqueness: string;
  bannerImg: string;
  gameLink: string;
  otherUrl: string;
  provider: string;
  source: string;
  gameId: number;
}

export interface GameListParams {
  // 来自首页的分类id
  categoryId: number;
  gameType?: string;
  source?: string;
  provicer?: string;
  orderByField?: string;
  isAsc?: boolean;
  pageNo?: number;
  pageSize?: number;
}

export interface GameCategoryParams {
  fieldName?: string;
  categoryId?: number;
}

export interface GameListItem {
  categoryId: number;
  // 图片地址
  gamePic: string;
  // 游戏价格
  gamePrice: number;
  // 游戏地址
  gameUrl: string;
  // 游戏厂商
  provider: string;
  // 游戏名称
  name: string;
  // 游戏厂商 判断跳转使用
  source: string;
  // 游戏id
  id: number;
  tripartiteUniqueness: string;
  playersNumber: number;
  otherUrl?: string;
}

export interface GamePageInfo {
  totalPages: number;
  totalSize: number;
  content: GameListItem[];
}

export interface SlotegratorStartParams {
  id: string;
  forFun: string;
}

/**
 * 首页视讯游戏列表
 * @param gameName 游戏名称（筛选）
 * @param sort 可不传 0 desc 1 asc
 */
export function getGameList(params: GameListParams) {
  return http.post<GameListParams, GamePageInfo>(
    'app/homeGames/getList',
    params,
  );
}

export function getBannersList() {
  return indusWinHttp.get<null, LiveBannerItem[]>('iGaming/liveCasinoBanner');
}

export function getLiveHotGameList() {
  return indusWinHttp.get<null, LiveHotGameItem[]>(
    'iGaming/liveCasinoHotGameList',
  );
}

export function authLive() {
  return indusWinHttp.post<null, {authorize: string}>('iGaming/sliveAuthorize');
}

export function getCategories(categoryId: number) {
  return http.get<GameCategoryParams, string[]>(
    'app/homeGames/getDistinctFieldValues',
    {
      params: {
        fieldName: 'game_type',
        categoryId,
      },
    },
  );
}

export function getSlotegratorGameStart(gameId: string) {
  return indusWinHttp.get<SlotegratorStartParams, string>(
    'casino/getGamesStart',
    {
      params: {
        id: gameId,
        forFun: 'false',
      },
    },
  );
}

export const categories: BasicObject[] = [
  {
    label: 'ALL',
    value: '',
  },
  {
    label: 'Roulette',
    value: 'Roulette',
  },
  {
    label: 'Blackjack',
    value: 'Blackjack',
  },
  {
    label: 'Sic Bo',
    value: 'Sic Bo',
  },
  {
    label: 'Dragon Tiger',
    value: 'Dragon Tiger',
  },
  {
    label: 'Pok Deng',
    value: 'Pok Deng',
  },
  {
    label: 'Andar Bahar',
    value: 'Andar Bahar',
  },
  {
    label: 'Teen Patti',
    value: 'Teen Patti',
  },
  {
    label: 'Baccarat',
    value: 'Baccarat',
  },
];

export const sortBy: BasicObject[] = [
  {
    label: 'Popular',
    value: '',
  },
  {
    label: 'A-Z',
    value: '1',
  },
  {
    label: 'Z-A',
    value: '0',
  },
];
