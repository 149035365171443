import theme from '@/style';
import React from 'react';
import {StyleSheet} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {BasicObject} from '@types';
const LiveVideo = ({
  videoUri = '',
  onPress = () => {},
}: {
  videoUri?: string;
  poster?: string;
  onPress?: (i: number) => void;
}) => {
  const currentTime = React.useRef<number>(0);

  return (
    <NativeTouchableOpacity
      onPress={() => onPress(Math.ceil(currentTime.current / 10))}
      style={[styles.container, theme.margin.lrl]}>
      {videoUri && (
        <video
          // eslint-disable-next-line react-native/no-inline-styles
          style={{flex: 1, objectFit: 'cover'}}
          src={videoUri}
          autoPlay
          muted
          loop
          playsInline
          onTimeUpdate={({target}: BasicObject) => {
            currentTime.current = target?.currentTime || 0;
          }}>
          Your browser does not support the video tag.
        </video>
      )}
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 176,
    borderRadius: 8,
    marginTop: 12,
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.backgroundColor.grey,
  },
  video: {
    flex: 1,
  },
});

export default LiveVideo;
